import React from "react"
import LayoutCourse from "../../../../layouts/course"
import Seo from "../../../../components/seo"
import Heading from "../../../../components/heading"
import Stack from "../../../../components/stack"
import Box from "../../../../components/box"
import CourseFooterNext from "../../../../components/course-footer-next"
import Paragraph from "../../../../components/paragraph"
import { getCourseNavigation } from "../../../../store/courses"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "hoelderlins-reisen" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          to="/kurse/hoelderlins-reisen/02-reiseerinnerung/handschrift-entziffern"
          title="Bist du bereit?"
          label="Los geht's"
        />
      }
      navigation={navigation}
    >
      <Seo title="Eine Reisestation wird zum Gedicht" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="span" color="whisper">
              Kapitel 2
            </Heading>
            <Heading as="h2" level={2}>
              Eine Reisestation wird zum Gedicht
            </Heading>
          </Stack>
          <Paragraph>
            Hölderlin kam auf seiner ersten großen Reise auch an einer Stadt
            vorbei, die ihm gefallen hat, wie keine andere Stadt. Einige Jahre
            später reiste er noch einmal dorthin. Und ganze 10 Jahre später
            schrieb er ein Gedicht über die Stadt. Um welche Stadt es sich wohl
            handeln mag? Finde es heraus und lerne Hölderlins Gedicht ein wenig
            näher kennen…
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
